<script>
import { mapMutations } from 'vuex'
import { $post } from '../http/index'
export default {
	data() {
		return {
			success: false,
			form: {
				name: '',
				email: '',
				phone: '',
				qtyHotdogs: '',
				qtyDrinks: '',
				date: '',
				timeStart: {
					time: '',
					ampm: 'AM'
				},
				timeEnd: {
					time: '',
					ampm: 'AM'
				},
				comments: '',
			}
		}
	},
	computed: {
		formData() {
			let data = JSON.stringify(this.form)
				data = JSON.parse(data)

			data.timeStart = `${this.form.timeStart.time} ${this.form.timeStart.ampm}`
			data.timeEnd = `${this.form.timeEnd.time} ${this.form.timeEnd.ampm}`
			return data
		},
		startTime() {
			return (this.form.timeStart.time) || false
		},
		endTime() {
			return (this.form.timeEnd.time) || false
		},
		checkForm() {
			let valid = true
			for (const key in this.form) {
				if (!this.form[key]) {
					valid = false
				}
			}

			if(!this.startTime || !this.endTime) {
				valid = false
			}

			return valid
		}
	},

	methods: {
		async submit() {
			let $api = $post;
			this.addLoader('Enviando datos del evento')
			try {
				let res = await $api.events(this.formData)
				this.removeLoader()
				this.success = true
			} catch (error) {
				let $error = error
				console.log($error)
				this.removeLoader()
			}	
		},

		...mapMutations({
			addLoader: 'addLoader',
			removeLoader: 'romoveLoader',
		})
	}
}
</script>
<template>
	<div class="pt-5 container">
		<div v-if="success">
			<div class="d-flex  justify-content-center flex-wrap px-2 px-xl-5 py-5">
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 pt-5">

					<div class="text-center">
						<h2 class="title">Datos enviados</h2>

						<p class="mt-5 col-8 mx-auto">
							Lo contaremos pronto para confirmar el evento.
						</p>

						<div class="col-md-8 mx-auto mt-5">
							<router-link :to="{name: 'HomeIndex'}" class="btn btn--block btn--primary">
								Hacer un pedido
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="text-center">
				<h2 class="title">¿Quieres Panshitos para tu evento?</h2>
				<h4 CLAS>Rellena el formulario siguiente y nos pondremos en contacto contigo lo antes posible.</h4>
			</div>
			<div class="d-flex flex-wrap mt-5">
				<div class="col-12 col-md-6 col-lg-5 px-2 px-lg-3 px-xl-3">
					<h3 class="title">Datos generales</h3>
					<div class="d-flex flex-wrap row-form mt-4">
						<div class="col-12 p-2">
							<label class="input">
								<span class="input__label">Nombre quien recibe</span>
								<input class="input__control" type="text" v-model="form.name">
							</label>
						</div>
						<div class="col-12 col-lg-6 p-2">
							<label class="input">
								<span class="input__label">Email</span>
								<input class="input__control" type="email" v-model="form.email">
							</label>
						</div>
						<div class="col-12 col-lg-6 p-2">
							<label class="input">
								<span class="input__label">Teléfono</span>
								<input class="input__control" type="text" v-model="form.phone" v-mask="'####-####'">
							</label>
						</div>
						<div class="col-12 col-lg-6 p-2">
							<label class="input">
								<span class="input__label">Cantidad de Panshitos</span>
								<input class="input__control text-center" type="text" v-model="form.qtyHotdogs" v-mask="'########'">
							</label>
						</div>
						<div class="col-12 col-lg-6 p-2">
							<label class="input">
								<span class="input__label">Cantidad de bebidas</span>
								<input class="input__control text-center" type="text" v-model="form.qtyDrinks" v-mask="'########'">
							</label>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 px-2 px-lg-3 px-xl-3">
					<h3 class="title">Fechas</h3>
					<div class="d-flex flex-wrap row-form mt-4">
						<div class="col-12 p-2">
							<label class="input">
								<span class="input__label">Fecha del evento</span>
								<input class="input__control font-weight-bold" type="date" v-model="form.date">
							</label>
						</div>
						<div class="col-12 col-md-10 p-2">
							<label class="input">
								<span class="input__label">Inicio del evento</span>
								<div class="d-flex">
									<input placeholder="hh:mm" class="input__control flex-grow-1 w-50 mr-2 text-center font-weight-bold" type="text"  v-model="form.timeStart.time" v-mask="'##:##'">
									<select class="input__control  flex-grow-1 w-50 "  v-model="form.timeStart.ampm">
										<option value="AM">AM</option>
										<option value="PM">PM</option>
									</select>
								</div>
							</label>
						</div>
						<div class="col-12 col-md-10 p-2">
							<label class="input">
								<span class="input__label">Final del evento</span>
								<div class="d-flex">
									<input placeholder="hh:mm" class="input__control flex-grow-1 w-50 mr-2 text-center font-weight-bold" type="text"  v-model="form.timeEnd.time" v-mask="'##:##'">
									<select class="input__control  flex-grow-1 w-50 "  v-model="form.timeEnd.ampm">
										<option value="AM">AM</option>
										<option value="PM">PM</option>
									</select>
								</div>
							</label>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-12 col-lg-4 px-2 px-lg-3 px-xl-3">
					<h3 class="title">Detalles</h3>
					<div class="d-flex flex-wrap row-form mt-4">
						<div class="col-12 p-2">
							<label class="input">
								<span class="input__label">¿Deseas compartirnos más detalles?</span>
								<textarea class="input__control font-weight-bold" rows="5" type="date" v-model="form.comments">
								</textarea>
							</label>
						</div>
					</div>

					<div class="col-12 text-center mt-4 px-5 pb-5">
						<button class="btn btn--primary btn--bold btn--block" :disabled="!checkForm" @click="submit">Enviar</button>
						<p v-if="!checkForm" class="text-danger texte-center small">*Es necesario llenar todos los campos</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>